import React from "react";
import Head from "../components/Head";
import Header from "../components/Header";
import PostIt from "../components/PostIt";
import Footer from "../components/Footer";

const PostBoard = () => {
  return (
    <div className="home-page">
      <Head />
      <Header />
      <main>
        <PostIt />
      </main>
      <Footer />
    </div>
  );
};

export default PostBoard;
