import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  onChildAdded,
  push,
  update,
  get,
} from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

// Load environment variables
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const storage = getStorage(app);

const PostIt = () => {
  const [message, setMessage] = useState("");
  const [posts, setPosts] = useState({});
  const [comments, setComments] = useState({});
  const [commentInput, setCommentInput] = useState({});
  const [fileName, setFileName] = useState("No file chosen");

  useEffect(() => {
    const postsRef = ref(db, "posts");
    const commentsRef = ref(db, "comments");

    // Using a Set to ensure comments are only added once
    const commentKeys = new Set();

    onChildAdded(postsRef, (snapshot) => {
      setPosts((prevPosts) => ({
        ...prevPosts,
        [snapshot.key]: {
          ...snapshot.val(),
          showComments: false, // Initialize showComments property
        },
      }));
    });

    onChildAdded(commentsRef, (snapshot) => {
      if (!commentKeys.has(snapshot.key)) {
        commentKeys.add(snapshot.key);
        setComments((prevComments) => ({
          ...prevComments,
          [snapshot.key]: snapshot.val(),
        }));
      }
    });
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && isImage(file.name)) {
      const storageRefPath = storageRef(storage, `images/${file.name}`);
      uploadBytes(storageRefPath, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setMessage(`pic: ${url}`);
          createPost(`pic: ${url}`);
        });
      });
      e.target.value = null; // Reset the file input
      setFileName("No file chosen");
    } else {
      alert("Needs to be an image!");
    }
  };

  const createPost = (msg) => {
    if (msg.trim() !== "") {
      const postsRef = ref(db, "posts");
      get(postsRef).then((snapshot) => {
        const res = snapshot.val();
        const postCt = res ? Object.keys(res).length + 1 : 1;
        push(postsRef, {
          postNum: postCt,
          body: msg,
          commentCT: 0,
          up: 0,
          down: 0,
        });
      });
      setMessage("");
      setFileName("No file chosen");
    } else {
      alert("You can't post nothing, bruh.");
    }
  };

  const handlePost = () => {
    createPost(message);
  };

  const handleComment = (postKey) => {
    const comment = commentInput[postKey] || "";
    if (comment.trim() !== "") {
      const postRef = ref(db, `posts/${postKey}`);
      get(postRef).then((snapshot) => {
        const data = snapshot.val();
        if (data) {
          const newCommentCT = data.commentCT + 1;
          update(postRef, { commentCT: newCommentCT });
          const commentsRef = ref(db, "comments");
          push(commentsRef, { forPost: postKey, body: comment });
          setCommentInput((prev) => ({ ...prev, [postKey]: "" }));
          setPosts((prevPosts) => ({
            ...prevPosts,
            [postKey]: {
              ...prevPosts[postKey],
              commentCT: newCommentCT,
            },
          }));
        } else {
          console.error(`Post with key ${postKey} not found`);
        }
      });
    } else {
      alert("You can't post nothing, bruh.");
    }
  };

  const handleLike = (postKey) => {
    const postRef = ref(db, `posts/${postKey}`);
    get(postRef).then((snapshot) => {
      const data = snapshot.val();
      if (data) {
        update(postRef, { up: data.up + 1 });
        setPosts((prevPosts) => ({
          ...prevPosts,
          [postKey]: {
            ...prevPosts[postKey],
            up: data.up + 1,
          },
        }));
      }
    });
  };

  const handleDislike = (postKey) => {
    const postRef = ref(db, `posts/${postKey}`);
    get(postRef).then((snapshot) => {
      const data = snapshot.val();
      if (data) {
        update(postRef, { down: data.down + 1 });
        setPosts((prevPosts) => ({
          ...prevPosts,
          [postKey]: {
            ...prevPosts[postKey],
            down: data.down + 1,
          },
        }));
      }
    });
  };

  const isImage = (filename) => {
    const ext = filename.split(".").pop().toLowerCase();
    return ["jpg", "jpeg", "png", "gif", "bmp"].includes(ext);
  };

  const toggleComments = (postKey) => {
    setPosts((prevPosts) => ({
      ...prevPosts,
      [postKey]: {
        ...prevPosts[postKey],
        showComments: !prevPosts[postKey].showComments,
      },
    }));
  };

  const handleCommentInputChange = (postKey, value) => {
    setCommentInput((prev) => ({ ...prev, [postKey]: value }));
  };

  return (
    <div className="wrapper contain">
      <form id="postComment" className="flex-container">
        <div id="txtBox">
          <textarea
            id="msg"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Write something..."
            className="w-100 pa2"
          ></textarea>
        </div>
        <div id="submit" className="center flex flex-column">
          <button
            type="button"
            className="center copy-button"
            onClick={handlePost}
          >
            Post
          </button>
          <span id="or" className="center">
            OR
          </span>
          <div className="center file-upload">
            <label htmlFor="file-upload" className="file-label">
              Choose File
            </label>
            <input
              type="file"
              id="file-upload"
              onChange={(e) => {
                handleFileUpload(e);
                setFileName(e.target.value.split("\\").pop());
              }}
              className="file-input w-100 mt1"
            />
            <span className="file-name">{fileName}</span>
          </div>
        </div>
      </form>
      <div id="result" className="flex flex-column-reverse w-100 pa3">
        {Object.entries(posts).map(([key, post]) => (
          <div key={key} className="postBox pa3 tl center">
            <h2 className="heading tl">Post #{post.postNum}</h2>
            <p className="comment">
              {post.body.startsWith("pic:") ? (
                <div className="image-container">
                  <img
                    src={post.body.substring(4)}
                    className="pics"
                    alt="uploaded"
                  />
                </div>
              ) : (
                post.body
              )}
            </p>
            <button className="like" onClick={() => handleLike(key)}>
              👍 ({post.up})
            </button>
            <button className="dislike" onClick={() => handleDislike(key)}>
              👎 ({post.down})
            </button>
            <button className="collapsible" onClick={() => toggleComments(key)}>
              Click to Add/View Comments ({post.commentCT})
            </button>
            {post.showComments && (
              <div id={`commentBox${post.postNum}`} className="commentBox">
                <input
                  className="txtBox w-100 pa2"
                  type="text"
                  placeholder="Write a comment..."
                  value={commentInput[key] || ""}
                  onChange={(e) =>
                    handleCommentInputChange(key, e.target.value)
                  }
                />
                <button
                  className="txtBtn copy-button"
                  onClick={() => handleComment(key)}
                >
                  Add Comment
                </button>
                {Object.values(comments)
                  .filter((c) => c.forPost === key)
                  .map((c, i) => (
                    <div key={i} className="replyBox">
                      <p className="reply">{c.body}</p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PostIt;
