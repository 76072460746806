import React from "react";
import cartoonImage from "../assets/images/cartoon.png";

const MainContent = () => {
  return (
    <div id="bod" className="contain">
      <article className="mainnn">
        <img id="meh" src={cartoonImage} alt="Cartoon" />
        <div className="text-container">
          <h2 id="numb">
            Post something! Play a game! Use my GPTs! Reach out! Donate!
          </h2>
          <p id="p1">
            Hello and welcome! You found my little place on the world wide web.
            My name is Josh Dluehosh, yes, I know it rhymes. I was born and
            raised in Tucson, AZ. After graduating high school, I moved up to
            Flagstaff, AZ to attend NAU for my undergraduate degree in
            Electronic Media & Film with a minor in Business. In 2015 I made the
            move out to Los Angeles to pursue my "dreams" and to "level up" from
            my hometown, so to speak.{" "}
          </p>
          <p id="p1">
            Now I have a number of hobbies and provide various services. I like
            to develop and play video games (please test my{" "}
            {
              <a className="demo-link" href="/games">
                demos
              </a>
            }
            ), code stuff (like this website), play with AI (check out my suite
            of custom tailored{" "}
            {
              <a className="demo-link" href="/gpts">
                GPTs
              </a>
            }
            ), and dabble in the web3 / crypto scene. I also throughly enjoy
            most outdoors activities, especially golf, and love creating content
            via videography, photography, and editing (reach out for shoots,
            etc). Feel free to take a look around and provide any feedback, you
            can also ask me anything, just make a{" "}
            {
              <a className="demo-link" href="/postit">
                post
              </a>
            }
            !{" "}
          </p>{" "}
          <p id="p1">
            For scheduling services, or other inquires, funding, job offers, or
            more personal questions, my email can be found on the{" "}
            {
              <a className="demo-link" href="/contact">
                contact
              </a>
            }{" "}
            page! Donations are highly appreciated! I hope you enjoy your stay!
          </p>
        </div>
      </article>
    </div>
  );
};

export default MainContent;
