import React from "react";
import Head from "../components/Head";
import Header from "../components/Header";
import Footer from "../components/Footer";

const NotFoundPage = () => {
  return (
    <div className="home-page">
      <Head />
      <Header />
      <main className="mainn">
        <h1>404 Not Found</h1>
        <p>The page you are looking for does not exist.</p>{" "}
      </main>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
