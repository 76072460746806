import React from "react";

const WritewMe = () => {
  return (
    <div className="mainn">
      <h1>Coming Soon!</h1>
    </div>
  );
};

export default WritewMe;
