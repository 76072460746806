import React from "react";

const Tower = () => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <iframe
          src={`${process.env.PUBLIC_URL}/TOWER CLIMBER/www/index.html`}
          title="Tower Climber"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
        />
      </div>
    </div>
  );
};

export default Tower;
