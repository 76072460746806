import React from "react";
import Head from "../components/Head";
import Header from "../components/Header";
import Footer from "../components/Footer";
import GPTs from "../components/GPTs";

const GPTPage = () => {
  return (
    <div className="home-page">
      <Head />
      <Header />
      <main>
        <GPTs />
      </main>
      <Footer />
    </div>
  );
};

export default GPTPage;
