import React from "react";
import { Helmet } from "react-helmet";

const Head = () => {
  return (
    <div>
      <Helmet>
        <title>WELCOME TO JDVAPOR LAND</title>
        <link
          href="https://fonts.googleapis.com/css?family=Stylish&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/tachyons@4.10.0/css/tachyons.min.css"
        />
      </Helmet>
    </div>
  );
};

export default Head;
