import React from "react";
import Head from "../components/Head";
import Header from "../components/Header";
import Games from "../components/Games";
import Footer from "../components/Footer";

const GamesPage = () => {
  return (
    <div className="home-page">
      <Head />
      <Header />
      <main>
        <Games />
      </main>
      <Footer />
    </div>
  );
};

export default GamesPage;
