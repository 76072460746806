import React from "react";
import Head from "../components/Head";
import Header from "../components/Header";
import Tower from "../components/Tower";
import Footer from "../components/Footer";

const AttackOnTower = () => {
  return (
    <div className="home-page">
      <Head />
      <Header />
      <main>
        <Tower />
      </main>
      <Footer />
    </div>
  );
};

export default AttackOnTower;
