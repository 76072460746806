import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import PostBoard from "./pages/PostBoard";
import GPTPage from "./pages/GPTPage";
import GamesPage from "./pages/GamesPage";
import "./App.css";
import WriteWithMe from "./pages/WriteWithMe";
import AttackOnTower from "./pages/AttackOnTower";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/postit" element={<PostBoard />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/gpts" element={<GPTPage />} />
          <Route path="/games" element={<GamesPage />} />
          <Route path="/writewithme" element={<WriteWithMe />} />
          <Route path="/attackontower" element={<AttackOnTower />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
