import React from "react";
import webWiz from "../assets/images/webwiz.png";
import golfCad from "../assets/images/gofl.png";
import storySynth from "../assets/images/synth.png";
import ghostWrite from "../assets/images/ghost.png";
import autoCad from "../assets/images/autocad.png";
import cocktailCon from "../assets/images/cocktail.png";
import codeMage from "../assets/images/codemage.png";
import gameCraft from "../assets/images/gamecraft.png";
import muse from "../assets/images/muse.png";
import seo from "../assets/images/seo.png";
import law from "../assets/images/law.png"
import sage from "../assets/images/sage.png"

const gptsData = [
  {
    id: 1,
    name: "Website Wizard",
    description: "Expert web developer for creating and managing web apps.",
    image: webWiz,
    link: "https://chatgpt.com/g/g-xNWc9vxXN-website-wizard",
  },
  {
    id: 2,
    name: "Ghost Writer",
    description: "Expert at expanding ideas into rich, long-form narratives.",
    image: ghostWrite,
    link: "https://chatgpt.com/g/g-GWOE4rMEn-ghost-writer",
  },
  {
    id: 3,
    name: "Storyboard Synthesizer",
    description:
      "Creates versatile, detailed visual narratives; seeks clarity or creatively interprets.",
    image: storySynth,
    link: "https://chatgpt.com/g/g-ogkNdSZyC-storyboard-synthesizer",
  },
  {
    id: 4,
    name: "MV Code Mage",
    description:
      "Expert in RPG Maker MV scripting, solutions, and sprite creation.",
    image: codeMage,
    link: "https://chatgpt.com/g/g-2iQiraK8f-mv-code-mage",
  },
  {
    id: 5,
    name: "Creative Muse",
    description:
      "A masterwork creative assisting in visualizing and refining art.",
    image: muse,
    link: "https://chatgpt.com/g/g-y7S0AvT2l-creative-muse",
  },
  {
    id: 6,
    name: "Golf Caddy Pro",
    description:
      "Expert golf caddy offering tailored advice and PGA rules knowledge.",
    image: golfCad,
    link: "https://chatgpt.com/g/g-Y5r4naCax-golf-caddy-pro",
  },
  {
    id: 7,
    name: "Cocktail Connoisseur",
    description:
      "Your lively master mixologist for all drink recipes and bar needs.",
    image: cocktailCon,
    link: "https://chatgpt.com/g/g-spboiNv0E-cocktail-connoisseur",
  },
  {
    id: 8,
    name: "GameCraft Master",
    description:
      "Collaborative expert in game mechanics and browser game development.",
    image: gameCraft,
    link: "https://chatgpt.com/g/g-Bmd4TBdtr-gamecraft-master",
  },
  {
    id: 9,
    name: "AutoCAD Guru",
    description:
      "Expert in AutoCAD Civil 3D Imperial for Land Surveyors, offering detailed guidance.",
    image: autoCad,
    link: "https://chatgpt.com/g/g-alXSvZESp-autocad-guru",
  },
  {
    id: 10,
    name: "SEO Savant",
    description: "SEO expert specializing in GPT store optimization",
    image: seo,
    link: "https://chatgpt.com/g/g-jRljsbbzz-seo-savant",
  },
  {
    id: 11,
    name: "Legal Eagle (AZ)",
    description: "AI lawyer for representation in Arizona legal matters.",
    image: law,
    link: "https://chatgpt.com/g/g-EyUWhKqDW-legal-eagle-az",
  },  {
    id: 12,
    name: "Software Sage",
    description: "World-class software engineer, master of tech, solving problems efficiently.",
    image: sage,
    link: "https://chatgpt.com/g/g-JMyeQO6e8-software-sage",
  },
];

const GPTs = () => {
  return (
    <div id="bod" className="contain">
      <div className="mainn">
        <h1>My Custom Built GPTs</h1>
        <p id="p1">
          Explore a wide range of tailored GPTs, each designed to assist in
          unique ways.
          <p>If you would like one made special for you, please reach out!</p>
        </p>
      </div>
      <div className="flex-container">
        {gptsData.map((gpt) => (
          <div key={gpt.id} className="gpt-container">
            <a href={gpt.link} target="_blank" rel="noopener noreferrer">
              <div className="gpt-buttons">
                <img src={gpt.image} alt={gpt.name} className="gpt" />
                <div className="gpt-text">{gpt.name}</div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GPTs;
