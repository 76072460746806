import React from "react";
import Head from "../components/Head";
import Header from "../components/Header";
import WritewMe from "../components/WritewMe";
import Footer from "../components/Footer";

const WriteWithMe = () => {
  return (
    <div className="home-page">
      <Head />
      <Header />
      <main>
        <WritewMe />
      </main>
      <Footer />
    </div>
  );
};

export default WriteWithMe;
